import { tailwind } from "@theme-ui/presets";

export default {
  ...tailwind,
  text: {
    contactHeading: {
      textAlign: "center",
      marginBottom: "20px",
      fontFamily: "Roboto, Sans-serif",
    },
    contactText: {
      textAlign: "center",
      whiteSpace: "nowrap",
    },
  },
  links: {
    services: {},
  },
};
