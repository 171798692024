/**
 * Internal dependencies.
 */
import fetch from "isomorphic-fetch";
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloLink,
} from "@apollo/client";
import { isEmpty } from "lodash";

// if ( process.browser ) {
// 	console.warn( 'hey', process.env.GATSBY_WORDPRESS_SITE_URL );
// }

/**
 * Middleware operation
 *
 * If we have a session token in localStorage, add it to the GraphQL request as a Session header.
 * If we have a auth token in localStorage, add it to the GraphQL request as a authorization header.
 */
export const middleware = new ApolloLink((operation, forward) => {
  let headersData = null;
//   let headersData = {};
//   headersData["accept"] = "application/json";
  const isServer = typeof window === "undefined";

  /**
   * If session data exist in local storage, set value as session header.
   */
  //   const session = process.browser ? localStorage.getItem("woo-session") : null;
  const session = isServer ? null : window.localStorage.getItem("woo-session");

  if (!isEmpty(session)) {
    headersData = {
      "woocommerce-session": `Session ${session}`,
    };
  }

  /**
   * If auth token exist in local storage, set value as authorization header.
   */
  //   const auth = process.browser
  //     ? JSON.parse(window.localStorage.getItem("auth"))
  //     : null;
  const auth = isServer
    ? null
    : JSON.parse(window.localStorage.getItem("auth"));
  const token = !isEmpty(auth) ? auth.authToken : null;

  if (!isEmpty(token)) {
    headersData = {
      ...headersData,
      authorization: token ? `Bearer ${token}` : "",
    };
  }

  if (!isEmpty(headersData)) {
    operation.setContext(({ headers = {} }) => ({
      headers: headersData,
    }));
  }

  return forward(operation);
});

/**
 * Afterware operation.
 *
 * This catches the incoming session token and stores it in window.localStorage, for future GraphQL requests.
 */
export const afterware = new ApolloLink((operation, forward) => {
  return forward(operation).map(response => {
    /**
     * Check for session header and update session in local storage accordingly.
     */
    const context = operation.getContext();
    const {
      response: { headers },
    } = context;
    const session = headers.get("woocommerce-session");

    if (session) {
      // Remove session data if session destroyed.
      if ("false" === session) {
        window.localStorage.removeItem("woo-session");

        // Update session new data if changed.
      } else if (window.localStorage.getItem("woo-session") !== session) {
        window.localStorage.setItem(
          "woo-session",
          headers.get("woocommerce-session")
        );
      }
    }

    return response;
  });
});

// Apollo GraphQL client.
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: middleware.concat(
    afterware.concat(
      createHttpLink({
        uri: "https://dev.nabytokdesign.sk/graphql/",
        fetch,
      })
    )
  ),
});

export default client;
