module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-catch-links-virtual-637cf00a21/0/cache/gatsby-plugin-catch-links-npm-4.4.0-731a9a167a-e6d392bf53.zip/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-image-virtual-8b07f19cc6/0/cache/gatsby-plugin-image-npm-2.4.0-ed0f819a1d-2e05fdd432.zip/node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-source-wordpress-virtual-f9d5840e10/0/cache/gatsby-source-wordpress-npm-6.4.0-a45611ab4d-126c7c2226.zip/node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://dev.nabytokdesign.sk/graphql","schema":{"requestConcurrency":3,"previewRequestConcurrency":2,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","timeout":30000,"perPage":100},"verbose":true,"develop":{"nodeUpdateInterval":3000,"hardCacheMediaFiles":false,"hardCacheData":false},"production":{"hardCacheMediaFiles":false,"allow404Images":false,"allow401Images":false},"debug":{"graphql":{"showQueryOnError":false,"showQueryVarsOnError":true,"copyQueryOnError":true,"panicOnError":true,"onlyReportCriticalErrors":true,"writeQueriesToDisk":true,"copyNodeSourcingQueryAndExit":false,"printIntrospectionDiff":false},"preview":false,"timeBuildSteps":false,"disableCompatibilityCheck":false,"throwRefetchErrors":false},"type":{"MediaItem":{"localFile":{"requestConcurrency":1,"excludeByMimeTypes":[],"maxFileSizeBytes":15728640},"createFileNodes":true,"lazyNodes":false},"Post":{"limit":5000},"Product":{"limit":5000},"UserRole":{"exclude":true},"User":{"exclude":true},"VisibleProduct":{"exclude":true},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"}},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-theme-apollo-virtual-d38af3758b/0/cache/gatsby-theme-apollo-npm-3.1.5-16378d118b-1fbc1999cb.zip/node_modules/gatsby-theme-apollo/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-manifest-virtual-a5086ef4bd/0/cache/gatsby-plugin-manifest-npm-4.4.0-252eb28031-b6b4471bd6.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"content/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4e05885e1f5403b3350fe3f31ed9a45e"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-offline-virtual-beeb4bf392/0/cache/gatsby-plugin-offline-npm-5.4.0-8d5b26acdf-280a82d7c9.zip/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-next-seo-virtual-9b07a147bb/0/cache/gatsby-plugin-next-seo-npm-1.9.0-32d46d00c4-713b84c98c.zip/node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-theme-ui-virtual-a40fae3dc6/0/cache/gatsby-plugin-theme-ui-npm-0.13.0-8d9d51958a-8d5b08d400.zip/node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-breadcrumb-virtual-201ce67989/0/cache/gatsby-plugin-breadcrumb-npm-12.3.0-c86a3f65bb-38c87b7864.zip/node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"Domov","exclude":["/dev-404-page/","/404/","/404.html","/offline-plugin-app-shell-fallback/"],"trailingSlashes":false},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-gdpr-cookies-virtual-6bbfc13814/0/cache/gatsby-plugin-gdpr-cookies-npm-2.0.8-7d33ec92fa-30fb844f0a.zip/node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"UA-123160497-1","cookieName":"nabytok-design-ga-cookies","anonymize":true,"allowAdFeatures":false},"environments":["production","development"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
